.p-panel {
    background-color: rgb(20, 21, 27);
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.p-panel-light {
    background-color: rgb(24, 25, 31);
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.p-panel-footer {
    background-color: rgb(31, 36, 46);
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.p-component,
.p-component * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.p-panel-body {
    padding: 2em;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1470px) {
}
