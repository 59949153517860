.board2 {
    position: relative;
    margin: 0 auto;
    background-color: transparent;
    background-image: linear-gradient(#333 1px, transparent 1px),
        linear-gradient(90deg, #333 1px, transparent 1px);
}

.cell2 {
    background: #ccc;
    position: absolute;
}
