body {
    margin: 0;
    padding: 0;
    font-size: 17px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

/* positioning */
.pull_right {
    float: right !important;
}

.pull_left {
    float: left !important;
}

/* text css */
.center_text {
    text-align: center;
}

.word_wrap {
    word-wrap: break-word;
    overflow-wrap: break-word;
}

/* hiding elements */
.display_none {
    display: none;
}

/* list items, without bulletpoints */
.footer_list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.footer_bar {
    color: white;
}

.dark_mode {
    background: #111;
    color: white;
}

html {
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
    height: 100%;
}

.main_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}
