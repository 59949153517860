.p-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -0.5em;
    margin-left: -0.5em;
    margin-top: -0.5em;
}

.p-nogutter {
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
}

.p-nogutter > .p-col,
.p-nogutter > [class*="p-col-"] {
    padding: 0;
}

.p-dir-rev {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.p-dir-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.p-dir-col-rev {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
}

.p-justify-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.p-justify-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.p-justify-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.p-justify-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.p-justify-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.p-justify-even {
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
}

.p-align-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.p-align-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.p-align-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.p-align-baseline {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
}

.p-align-stretch {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.p-col {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    padding: 0.5em;
}

.p-col-fixed {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding: 0.5em;
}

.p-col-align-start {
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.p-col-align-end {
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.p-col-align-center {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.p-col-align-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline;
}

.p-col-align-stretch {
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
}

.p-col-1,
.p-col-2,
.p-col-3,
.p-col-4,
.p-col-5,
.p-col-6,
.p-col-7,
.p-col-8,
.p-col-9,
.p-col-10,
.p-col-11,
.p-col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding: 0.5em;
}

.p-col-1 {
    width: 8.3333%;
}

.p-col-2 {
    width: 16.6667%;
}

.p-col-3 {
    width: 25%;
}

.p-col-4 {
    width: 33.3333%;
}

.p-col-5 {
    width: 41.6667%;
}

.p-col-6 {
    width: 50%;
}

.p-col-7 {
    width: 58.3333%;
}

.p-col-8 {
    width: 66.6667%;
}

.p-col-9 {
    width: 75%;
}

.p-col-10 {
    width: 83.3333%;
}

.p-col-11 {
    width: 91.6667%;
}

.p-col-12 {
    width: 100%;
}

.p-offset-12 {
    margin-left: 100%;
}

.p-offset-11 {
    margin-left: 91.66666667%;
}

.p-offset-10 {
    margin-left: 83.33333333%;
}

.p-offset-9 {
    margin-left: 75%;
}

.p-offset-8 {
    margin-left: 66.66666667%;
}

.p-offset-7 {
    margin-left: 58.33333333%;
}

.p-offset-6 {
    margin-left: 50%;
}

.p-offset-5 {
    margin-left: 41.66666667%;
}

.p-offset-4 {
    margin-left: 33.33333333%;
}

.p-offset-3 {
    margin-left: 25%;
}

.p-offset-2 {
    margin-left: 16.66666667%;
}

.p-offset-1 {
    margin-left: 8.33333333%;
}

.p-offset-0 {
    margin-left: 0%;
}

.p-sm-1,
.p-sm-2,
.p-sm-3,
.p-sm-4,
.p-sm-5,
.p-sm-6,
.p-sm-7,
.p-sm-8,
.p-sm-9,
.p-sm-10,
.p-sm-11,
.p-sm-12,
.p-md-1,
.p-md-2,
.p-md-3,
.p-md-4,
.p-md-5,
.p-md-6,
.p-md-7,
.p-md-8,
.p-md-9,
.p-md-10,
.p-md-11,
.p-md-12,
.p-lg-1,
.p-lg-2,
.p-lg-3,
.p-lg-4,
.p-lg-5,
.p-lg-6,
.p-lg-7,
.p-lg-8,
.p-lg-9,
.p-lg-10,
.p-lg-11,
.p-lg-12,
.p-xl-1,
.p-xl-2,
.p-xl-3,
.p-xl-4,
.p-xl-5,
.p-xl-6,
.p-xl-7,
.p-xl-8,
.p-xl-9,
.p-xl-10,
.p-xl-11,
.p-xl-12 {
    padding: 0.5em;
}

.p-col-nogutter {
    padding: 0;
}

@media screen and (min-width: 576px) {
    .p-sm-1,
    .p-sm-2,
    .p-sm-3,
    .p-sm-4,
    .p-sm-5,
    .p-sm-6,
    .p-sm-7,
    .p-sm-8,
    .p-sm-9,
    .p-sm-10,
    .p-sm-11,
    .p-sm-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }

    .p-sm-1 {
        width: 8.3333%;
    }

    .p-sm-2 {
        width: 16.6667%;
    }

    .p-sm-3 {
        width: 25%;
    }

    .p-sm-4 {
        width: 33.3333%;
    }

    .p-sm-5 {
        width: 41.6667%;
    }

    .p-sm-6 {
        width: 50%;
    }

    .p-sm-7 {
        width: 58.3333%;
    }

    .p-sm-8 {
        width: 66.6667%;
    }

    .p-sm-9 {
        width: 75%;
    }

    .p-sm-10 {
        width: 83.3333%;
    }

    .p-sm-11 {
        width: 91.6667%;
    }

    .p-sm-12 {
        width: 100%;
    }

    .p-sm-offset-12 {
        margin-left: 100%;
    }

    .p-sm-offset-11 {
        margin-left: 91.66666667%;
    }

    .p-sm-offset-10 {
        margin-left: 83.33333333%;
    }

    .p-sm-offset-9 {
        margin-left: 75%;
    }

    .p-sm-offset-8 {
        margin-left: 66.66666667%;
    }

    .p-sm-offset-7 {
        margin-left: 58.33333333%;
    }

    .p-sm-offset-6 {
        margin-left: 50%;
    }

    .p-sm-offset-5 {
        margin-left: 41.66666667%;
    }

    .p-sm-offset-4 {
        margin-left: 33.33333333%;
    }

    .p-sm-offset-3 {
        margin-left: 25%;
    }

    .p-sm-offset-2 {
        margin-left: 16.66666667%;
    }

    .p-sm-offset-1 {
        margin-left: 8.33333333%;
    }

    .p-sm-offset-0 {
        margin-left: 0%;
    }
}
@media screen and (min-width: 768px) {
    .p-md-1,
    .p-md-2,
    .p-md-3,
    .p-md-4,
    .p-md-5,
    .p-md-6,
    .p-md-7,
    .p-md-8,
    .p-md-9,
    .p-md-10,
    .p-md-11,
    .p-md-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }

    .p-md-1 {
        width: 8.3333%;
    }

    .p-md-2 {
        width: 16.6667%;
    }

    .p-md-3 {
        width: 25%;
    }

    .p-md-4 {
        width: 33.3333%;
    }

    .p-md-5 {
        width: 41.6667%;
    }

    .p-md-6 {
        width: 50%;
    }

    .p-md-7 {
        width: 58.3333%;
    }

    .p-md-8 {
        width: 66.6667%;
    }

    .p-md-9 {
        width: 75%;
    }

    .p-md-10 {
        width: 83.3333%;
    }

    .p-md-11 {
        width: 91.6667%;
    }

    .p-md-12 {
        width: 100%;
    }

    .p-md-offset-12 {
        margin-left: 100%;
    }

    .p-md-offset-11 {
        margin-left: 91.66666667%;
    }

    .p-md-offset-10 {
        margin-left: 83.33333333%;
    }

    .p-md-offset-9 {
        margin-left: 75%;
    }

    .p-md-offset-8 {
        margin-left: 66.66666667%;
    }

    .p-md-offset-7 {
        margin-left: 58.33333333%;
    }

    .p-md-offset-6 {
        margin-left: 50%;
    }

    .p-md-offset-5 {
        margin-left: 41.66666667%;
    }

    .p-md-offset-4 {
        margin-left: 33.33333333%;
    }

    .p-md-offset-3 {
        margin-left: 25%;
    }

    .p-md-offset-2 {
        margin-left: 16.66666667%;
    }

    .p-md-offset-1 {
        margin-left: 8.33333333%;
    }

    .p-md-offset-0 {
        margin-left: 0%;
    }
}
@media screen and (min-width: 992px) {
    .p-lg-1,
    .p-lg-2,
    .p-lg-3,
    .p-lg-4,
    .p-lg-5,
    .p-lg-6,
    .p-lg-7,
    .p-lg-8,
    .p-lg-9,
    .p-lg-10,
    .p-lg-11,
    .p-lg-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }

    .p-lg-1 {
        width: 8.3333%;
    }

    .p-lg-2 {
        width: 16.6667%;
    }

    .p-lg-3 {
        width: 25%;
    }

    .p-lg-4 {
        width: 33.3333%;
    }

    .p-lg-5 {
        width: 41.6667%;
    }

    .p-lg-6 {
        width: 50%;
    }

    .p-lg-7 {
        width: 58.3333%;
    }

    .p-lg-8 {
        width: 66.6667%;
    }

    .p-lg-9 {
        width: 75%;
    }

    .p-lg-10 {
        width: 83.3333%;
    }

    .p-lg-11 {
        width: 91.6667%;
    }

    .p-lg-12 {
        width: 100%;
    }

    .p-lg-offset-12 {
        margin-left: 100%;
    }

    .p-lg-offset-11 {
        margin-left: 91.66666667%;
    }

    .p-lg-offset-10 {
        margin-left: 83.33333333%;
    }

    .p-lg-offset-9 {
        margin-left: 75%;
    }

    .p-lg-offset-8 {
        margin-left: 66.66666667%;
    }

    .p-lg-offset-7 {
        margin-left: 58.33333333%;
    }

    .p-lg-offset-6 {
        margin-left: 50%;
    }

    .p-lg-offset-5 {
        margin-left: 41.66666667%;
    }

    .p-lg-offset-4 {
        margin-left: 33.33333333%;
    }

    .p-lg-offset-3 {
        margin-left: 25%;
    }

    .p-lg-offset-2 {
        margin-left: 16.66666667%;
    }

    .p-lg-offset-1 {
        margin-left: 8.33333333%;
    }

    .p-lg-offset-0 {
        margin-left: 0%;
    }
}
@media screen and (min-width: 1200px) {
    .p-xl-1,
    .p-xl-2,
    .p-xl-3,
    .p-xl-4,
    .p-xl-5,
    .p-xl-6,
    .p-xl-7,
    .p-xl-8,
    .p-xl-9,
    .p-xl-10,
    .p-xl-11,
    .p-xl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }

    .p-xl-1 {
        width: 8.3333%;
    }

    .p-xl-2 {
        width: 16.6667%;
    }

    .p-xl-3 {
        width: 25%;
    }

    .p-xl-4 {
        width: 33.3333%;
    }

    .p-xl-5 {
        width: 41.6667%;
    }

    .p-xl-6 {
        width: 50%;
    }

    .p-xl-7 {
        width: 58.3333%;
    }

    .p-xl-8 {
        width: 66.6667%;
    }

    .p-xl-9 {
        width: 75%;
    }

    .p-xl-10 {
        width: 83.3333%;
    }

    .p-xl-11 {
        width: 91.6667%;
    }

    .p-xl-12 {
        width: 100%;
    }

    .p-xl-offset-12 {
        margin-left: 100%;
    }

    .p-xl-offset-11 {
        margin-left: 91.66666667%;
    }

    .p-xl-offset-10 {
        margin-left: 83.33333333%;
    }

    .p-xl-offset-9 {
        margin-left: 75%;
    }

    .p-xl-offset-8 {
        margin-left: 66.66666667%;
    }

    .p-xl-offset-7 {
        margin-left: 58.33333333%;
    }

    .p-xl-offset-6 {
        margin-left: 50%;
    }

    .p-xl-offset-5 {
        margin-left: 41.66666667%;
    }

    .p-xl-offset-4 {
        margin-left: 33.33333333%;
    }

    .p-xl-offset-3 {
        margin-left: 25%;
    }

    .p-xl-offset-2 {
        margin-left: 16.66666667%;
    }

    .p-xl-offset-1 {
        margin-left: 8.33333333%;
    }

    .p-xl-offset-0 {
        margin-left: 0%;
    }
}

/*# sourceMappingURL=primegrid.css.map */
