.container {
    position: relative;
}

.page {
    position: absolute;
    width: 100%;
    height: 100%;
}

.page-enter {
    opacity: 0;
}

.page-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.page-exit {
    opacity: 1;
}

.page-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.nav:hover .nav_menu {
    opacity: 0.4;
}

.nav_menu {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 50%;
    margin: 15px 15px 15px 15px;
    background: rgba(0, 0, 0, 0);
    display: inline-block;
    color: white;
    text-decoration: none;
    -webkit-transition: 0.15s;
    transition: 0.15s;
}

.nav:hover .nav_menu:hover {
    opacity: 1;
}

.nav_logo:hover {
    -webkit-filter: brightness(1.25);
    filter: brightness(1.25);
}

.gallery {
    background-color: rgb(20, 21, 27);
}

.custom_button {
    text-transform: uppercase;
    font-weight: bold;
}

.pseudo_button {
    background-color: transparent;
    border-color: gray;
    border-style: solid;
    border-width: 2px;
    cursor: pointer;
    padding-left: 2em;
}

.custom_sidebar {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -210px;
    margin-left: -210px;
    width: 420px;
    height: 420px;
}

.pseudo_button:hover {
    background-color: rgb(70, 70, 70);
    transition: 0.5s;
}

.dark_background {
    z-index: -1;
    background: rgb(20, 21, 27);
}

.push_down {
    padding-top: 2.5em;
}

.main_content {
    line-height: 1.5;
    color: lightgray !important;
}

.bold {
    font-weight: bold;
}

.uppercase {
    text-transform: uppercase;
}

.shadow {
    text-shadow: 2px 2px 4px #000000;
}

.white {
    color: white;
}

.fade-enter {
    opacity: 15;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 100ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 100ms ease-in;
}

.transition-group {
    position: relative;
}

.route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
    .home {
        background-image: url("../assets/img/home/home-768-min.png");
    }
    .about {
        background-image: url("../assets/img/about/about-768-min.jpg");
    }
    .resume {
        background-image: url("../assets/img/resume/resume-768-min.jpg");
    }
    .projects {
        background-image: url("../assets/img/projects/project-768-min.jpg");
    }
    .contact {
        background-image: url("../assets/img/contact/contact-768-min.jpg");
    }
    .contact_map {
        background-image: url("../assets/img/contact/map-768-min.jpg");
    }
    .nav_logo {
        color: white;
        text-align: center;
        text-decoration: none;
        float: left;
        margin-left: 3em;
        margin-top: 1.6em;
        width: 2.5em;
        height: 2.5em;
        -webkit-transition: 0.15s;
        transition: filter 0.15s;
    }
    .nav {
        display: none;
    }
    .mobile_nav:hover .nav_menu:hover {
        opacity: 1;
    }
    .mobile_nav:hover .nav_menu {
        opacity: 0.4;
    }
    .mobile_nav {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        overflow: hidden;
        padding: 1em;
        -webkit-transition: 0.5s;
        transition: 0.5s;
    }
    .p-grid-centered {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: auto;
        -webkit-transition: 0.5s;
        transition: 0.5s;
    }
    .center_content {
        margin: auto;
        -webkit-transition: 0.5s;
        transition: 0.5s;
    }
    .center_blog_content {
        margin: auto;
        -webkit-transition: 0.5s;
        transition: 0.5s;
    }
    .work_exp {
        padding: 1em;
    }
    .p-col-4-auto {
        width: 100%;
    }
    .p-col-3-auto {
        width: 100%;
    }
    .p-col-9-auto {
        width: 100%;
    }
    .p-col-6-auto {
        width: 100%;
    }
    .header {
        font-size: 3em;
        margin-bottom: 2.5em;
        font-weight: bold;
        text-transform: uppercase;
    }

    .subheader {
        font-size: 1.5em;
        font-style: italic;
        margin-top: 4.5em;
        margin-bottom: -2em;
    }

    .title {
        font-size: 2em;
    }

    .subtitle {
        font-size: 1.5em;
    }

    .description {
        font-size: 1em;
    }
    .blog_nav {
        bottom: 200px;
        position: absolute;
        left: 0px;
        right: 0px;
    }
    .panel_bottom_space {
        margin-bottom: 0.75em;
    }
    .project_image {
        height: 200px;
    }
    .card_height {
        height: 350px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .home {
        background-image: url("../assets/img/home/home-992-min.png");
    }
    .about {
        background-image: url("../assets/img/about/about-992-min.jpg");
    }
    .resume {
        background-image: url("../assets/img/resume/resume-992-min.jpg");
    }
    .projects {
        background-image: url("../assets/img/projects/project-992-min.jpg");
    }
    .contact {
        background-image: url("../assets/img/contact/contact-992-min.jpg");
    }
    .contact_map {
        background-image: url("../assets/img/contact/map-992-min.jpg");
    }
    .nav_logo {
        color: white;
        text-align: center;
        text-decoration: none;
        margin-left: 3em;
        margin-top: 1.6em;
        float: left;
        width: 2.5em;
        height: 2.5em;
        -webkit-transition: 0.15s;
        transition: filter 0.15s;
    }
    .nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        margin-right: 3em;
        margin-top: 1em;
        float: right;
        overflow: hidden;
        -webkit-transition: 0.5s;
        transition: opacity 0.5s;
    }
    .mobile_nav_button {
        display: none;
    }
    .p-grid-centered {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: auto;
        -webkit-transition: 0.5s;
        transition: 0.5s;
    }
    .center_content {
        margin: auto;
        -webkit-transition: 0.5s;
        transition: 0.5s;
    }
    .center_blog_content {
        margin: auto;
        -webkit-transition: 0.5s;
        transition: 0.5s;
    }
    .work_exp {
        padding: 2em;
    }
    .p-col-4-auto {
        width: 50%;
    }
    .p-col-3-auto {
        width: 100%;
    }
    .p-col-9-auto {
        width: 100%;
    }
    .p-col-6-auto {
        width: 50%;
    }
    .privacy {
        padding: 1em 7em 1em 7em;
    }
    .header {
        font-size: 5em;
        margin-bottom: 2.5em;
        font-weight: bold;
        text-transform: uppercase;
    }
    .subheader {
        font-size: 2em;
        font-style: italic;
        margin-top: 4.5em;
        margin-bottom: -2em;
    }
    .title {
        font-size: 3em;
    }
    .subtitle {
        font-size: 1.55em;
        margin-bottom: 2.5em;
    }
    .description {
        font-size: 1.1em;
        margin-bottom: 2.5em;
    }
    .blog_nav {
        bottom: 200px;
        position: absolute;
        left: 0px;
        right: 0px;
    }
    .panel_bottom_space {
        margin-bottom: 1em;
    }
    .project_image {
        height: 225px;
    }
    .card_height {
        height: 385px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .home {
        background-image: url("../assets/img/home/home-1470-min.png");
    }
    .about {
        background-image: url("../assets/img/about/about-1470-min.jpg");
    }
    .resume {
        background-image: url("../assets/img/resume/resume-1470-min.jpg");
    }
    .projects {
        background-image: url("../assets/img/projects/project-1470-min.jpg");
    }
    .contact {
        background-image: url("../assets/img/contact/contact-1470-min.jpg");
    }
    .contact_map {
        background-image: url("../assets/img/contact/map-1470-min.jpg");
    }
    .center_blog_content {
        width: 992px;
        margin: auto;
        -webkit-transition: 0.5s;
        transition: 0.5s;
    }
    .blog_nav {
        bottom: 250px;
        position: absolute;
        left: 0px;
        right: 0px;
    }
    .project_image {
        height: 250px;
    }
    .card_height {
        height: 400px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .p-col-3-auto {
        width: 25%;
    }
    .p-col-9-auto {
        width: 75%;
    }
    .p-col-6-auto {
        width: 50%;
    }
    .project_image {
        height: 300px;
    }
    .card_height {
        height: 435px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1470px) {
    .home {
        background-image: url("../assets/img/home/home-min.png");
    }
    .about {
        background-image: url("../assets/img/about/about-min.jpg");
    }
    .resume {
        background-image: url("../assets/img/resume/resume-min.jpg");
    }
    .projects {
        background-image: url("../assets/img/projects/project-min.jpg");
    }
    .contact {
        background-image: url("../assets/img/contact/contact-min.jpg");
    }
    .contact_map {
        background-image: url("../assets/img/contact/map-min.jpg");
    }
    .nav_logo {
        color: white;
        text-align: center;
        text-decoration: none;
        float: left;
        margin-left: 0;
        margin-top: 1.6em;
        width: 2.5em;
        height: 2.5em;
        -webkit-transition: 0.15s;
        transition: filter 0.15s;
    }
    .nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        margin-right: 0;
        margin-top: 1em;
        float: right;
        overflow: hidden;
        -webkit-transition: 0.5s;
        transition: opacity 0.5s;
    }
    .p-grid-centered {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        margin: 0;
        padding: 0;
        overflow: hidden;
        width: 1366px;
        margin: auto;
    }
    .center_content {
        width: 1366px;
        margin: auto;
        -webkit-transition: 0.5s;
        transition: 0.5s;
    }
    .p-col-4-auto {
        width: 33.333%;
    }
    .project_image {
        width: 100%;
    }
    .card_height {
        height: 455px;
    }
}
